import React, { useState, useEffect } from "react";
import Papa from "papaparse"; // For CSV parsing
import {
  getStudentByEmail,
  updateStudentByEmail,
  updateStudentMemberIdByEmail,
  deleteStudentByEmail
} from "../actions/formActions";
import { removeDevice } from '../actions/adminActions'
import { ProtectAdminRoute } from "../manageRoutes/ProtectRoutes";
import AdminNavBar from "../compoents/AdminNavBar";

const UpdateMinorStudent = () => {
  const [file, setFile] = useState(null);
  const [students, setStudents] = useState([]);
  const [email, setEmail] = useState("");
  const [seatno, setSeatno] = useState("");
  const [memberid, setMemberId] = useState("");
  const [student, setStudent] = useState(null); // Store fetched student details
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);

  // CSV Handling
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleParse = () => {
    if (!file) return setError("Please upload a CSV file.");

    Papa.parse(file, {
      header: true, // Assumes the CSV file has a header row
      complete: (result) => {
        setStudents(result.data); // Parsed data (list of students with emails and roll numbers)
        setError(null);
      },
      error: (err) => {
        setError("Error parsing CSV file.");
      },
    });
  };

  const handleBatchUpdate = async () => {
    let updatedCount = 0;

    for (const student of students) {
      const { email, seatno } = student; // Assuming the CSV contains columns `email` and `seatno`

      try {
        await getStudentByEmail(email);
        await updateStudentByEmail(email, { seatno });
        updatedCount++;
        setProgress((updatedCount / students.length) * 100); // Update progress
      } catch (err) {
        console.error(`Error updating student with email ${email}: ${err}`);
        setError(`Failed to update student with email: ${email}`);
      }
    }

    alert("Batch update completed.");
  };

  // Single Student Handling
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(null)
  const seatnoRegex = /^\d{11}$/;
  const memberIdRegex = /^\d{10}$/;

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);


  const handleFetchStudent = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@somaiya\.edu$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
      return;
    }

    try {
      const fetchedStudent = await getStudentByEmail(email);
      setStudent(fetchedStudent); // Save the fetched student data
      setError(null);
    } catch (err) {
      setError("Failed to fetch student data");
    }
  };

  const handleSeatnoChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,11}$/.test(value)) {
      setSeatno(value);
    }
  };

  const handleMemberIdChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setMemberId(value);
    }
  };

  const handleUpdateStudentSeatNumber = async () => {
    if (!seatnoRegex.test(seatno)) {
      setError("Invalid seat number. Must be 11 digits.");
      return;
    }

    try {
      await updateStudentByEmail(email, { seatno });
      setError(null);
      alert("Seat number updated successfully");
    } catch (err) {
      setError("Failed to update student data");
      alert("Failed to update student");
    }
  };

  const handleUpdateStudentMemberIdByEmail = async () => {
    if (!memberIdRegex.test(memberid)) {
      setError("Invalid member ID. Must be 10 digits.");
      return;
    }

    try {
      await updateStudentMemberIdByEmail(email, { memberid });
      setError(null);
      alert("Member ID updated successfully");
    } catch (err) {
      setError("Failed to update student data");
      alert("Failed to update student");
    }
  };

  const handleDeleteStudent = async () => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      try {
        await deleteStudentByEmail(email);
        alert("Student deleted successfully");
        setStudent(null); // Clear student data after deletion
        setEmail("");
        setSeatno("");
        setError(null);
      } catch (err) {
        setError("Failed to delete student");
        alert("Failed to delete student");
      }
    }
  };

  const handleDeviceRemoval = async () => {
    setLoading(true);
    //make api call to remove device (deviceId : null)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@somaiya\.edu$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
      setSuccess(null);
      return;
    }
    try {
      await removeDevice(email);
      setSuccess("Device removed successfully");
      setError(null);
    } catch (err) {
      setError("Failed to remove device", err);
      setSuccess(null);
    } finally {
      setLoading(false)
    }
  }
  return (
    <ProtectAdminRoute>
      <AdminNavBar />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Single Student Update */}
        <div style={{ flex: 1, padding: "20px" }}>
          <h2>Update Single Student</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}

          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            style={{ width: "16vw" }}
          />
          <button onClick={handleFetchStudent}>Fetch Student</button>

          {student && (
            <div style={{ marginTop: "20px" }}>
              <h3>Fetched Student Details:</h3>
              <table border="1" cellPadding="10" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Seat No</th>
                    <th>Mobile No</th>
                    <th>Program Name</th>
                    <th>Professional Course</th>
                    <th>Language</th>
                    <th>Member ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{student.name}</td>
                    <td>{student.email}</td>
                    <td>{student.seatno}</td>
                    <td>{student.mobileno}</td>
                    <td>{student.programName}</td>
                    <td>{student.professionalcourse}</td>
                    <td>{student.language}</td>
                    <td>{student.memberid}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <div style={{ marginTop: "15px" }}>
            <input
              type="text"
              value={seatno}
              onChange={handleSeatnoChange}
              placeholder="Enter seat number (11 digits)"
              style={{ width: "16vw" }}
            />
          </div>
          <button onClick={handleUpdateStudentSeatNumber}>
            Update Seat Number
          </button>
          <br />
          <br />
          {student &&
            <div>
              <div style={{ marginTop: "15px" }}>
                <input
                  type="text"
                  value={seatno}
                  onChange={handleSeatnoChange}
                  placeholder="Enter seat number (11 digits)"
                  style={{ width: "16vw" }}
                />
                <button onClick={handleUpdateStudentSeatNumber}>
                  Update Seat Number
                </button>
              </div>

              <div style={{ marginTop: "15px" }}>
                <input
                  type="text"
                  value={memberid}
                  onChange={handleMemberIdChange}
                  placeholder="Enter member id (10 digits)"
                  style={{ width: "16vw" }}
                />
                <button onClick={handleUpdateStudentMemberIdByEmail}>
                  Update Member ID
                </button>
              </div>

              <button style={{ marginTop: "15px" }} onClick={handleDeleteStudent}>Delete Student</button>

              <button style={{ marginTop: "15px" }} disabled={loading} onClick={handleDeviceRemoval}>{loading ? "Removing..." : "Remove Previous Device"}</button>
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}

            </div>
          }
          <div>
            <input
              type="text"
              value={memberid}
              onChange={handleMemberIdChange}
              placeholder="Enter member ID (10 digits)"
              style={{ width: "16vw" }}
            />
          </div>
          <button onClick={handleUpdateStudentMemberIdByEmail}>
            Update Member ID
          </button>
        </div>

        {/* Batch Update */}
        <div style={{ flex: 1, padding: "20px" }}>
          <h2>Batch Update Students from CSV</h2>
          {error && <p style={{ color: "red" }}>{error}</p>}

          <input type="file" accept=".csv" onChange={handleFileChange} />
          <button onClick={handleParse}>Parse CSV</button>

          {students.length > 0 && (
            <>
              <p>Found {students.length} records in the CSV file.</p>
              <button onClick={handleBatchUpdate}>Update All Students</button>
              <div>
                <p>Progress: {progress.toFixed(2)}%</p>
              </div>
            </>
          )}
        </div>
      </div>
    </ProtectAdminRoute>
  );
};

export default UpdateMinorStudent;
