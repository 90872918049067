import { useEffect, useState } from "react";
import AdminNavBar from "../compoents/AdminNavBar"
import { ProtectAdminRoute } from "../manageRoutes/ProtectRoutes"
import { useAuthStore } from "../store/store";
import { closePortalById, deletePortalById, getAllActivePortals, getFilteredAttendance } from "../actions/attendanceActions";
import { Collapse } from "@mui/material";
import { Link } from "react-router-dom";
import { getAllCourses, getDistinctCourses } from "../actions/courseActions";

const ActivePortals = () => {
    const now = new Date();
    const user = useAuthStore.getState().user
    const [uniqueCourses, setUniqueCourses] = useState([]);
    const [uniqueSemesters, setUniqueSemesters] = useState([]) //Initial value
    const [courses, setCourses] = useState("");
    const [startDate, setStartDate] = useState(new Date()); // Initial value
    const [endDate, setEndDate] = useState(new Date()); // Initial value
    const [errorMessage, setErrorMessage] = useState("")
    const [showError, setShowError] = useState(false)
    const [attendanceData, setAttendanceData] = useState([])
    const [filteredAttendance, setFilteredAttendance] = useState([])
    const [filterSemesterDropdown, setFilterSemesterDropdown] = useState(uniqueSemesters[0])
    const [filterCourseDropdown, setFilterCourseDropdown] = useState(uniqueCourses[0])
    const [showFilterForm, setShowFilterForm] = useState(false)
    const [showFilteredData, setShowFilteredData] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        const fetchCourses = async () => {
            const res = await getAllCourses();
            setCourses(res.courses);
        }
        fetchCourses();
    }, []);

    useEffect(() => {
        getAllActivePortals(user?.teacherData?._id).then(res => {
            if (res !== undefined) {
                setAttendanceData(res.activePortals.reverse())
                setShowFilteredData(false)
                setFilteredAttendance([])
            } else {
                setShowError(true)
                setErrorMessage("Server Down, Contact Dev Cell")
            }
        })
    }, [refresh]);

    const filterAttendance = () => {
        getFilteredAttendance(user._id, filterCourseDropdown, filterSemesterDropdown, startDate, endDate).then(res => {
            setFilteredAttendance(res.portal.reverse())
            setShowFilteredData(true)
        })
    }

    const handleCourseChange = (event) => {
        const courseName = event.target.value;
        setFilterCourseDropdown(courseName);

        const semestersForSelectedCourse = [
            ...new Set(
                courses
                    .filter((el) => el.courseName === courseName)
                    .map((el) => el.semester)
            ),
        ];

        // If the course is found, set uniqueSemesters to the semesters of the selected course
        if (semestersForSelectedCourse) {
            setUniqueSemesters(semestersForSelectedCourse); // Assuming course.semesters is an array of semesters
        }
    };

    const fetchCourseNames = async () => {
        setShowFilterForm((prev) => !prev)
        const res = await getDistinctCourses();
        setUniqueCourses(res.data);
        setFilterCourseDropdown(res.data[0]); // Set the initial value for the course dropdown

        const semestersForSelectedCourse = [
            ...new Set(
                courses
                    ?.filter((el) => el.courseName === res.data[0])
                    .map((el) => el.semester)
            ),
        ];

        if (semestersForSelectedCourse) {
            setUniqueSemesters(semestersForSelectedCourse);
        }
    };
    return (
        <ProtectAdminRoute>
            <AdminNavBar />
            <div className="container-fluid">
                <Collapse in={!showFilteredData}>
                    <div className="p-2">
                        {attendanceData.length !== 0 ?
                            (
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    {/* <button className="btn btn-sm btn-warning col-md-3 col-10 my-3" onClick={() => setRefresh(prevState => !prevState)}>Refresh</button> */}
                                    <h4 className="text-danger px-2">Portals</h4>
                                </div>)
                            : null}
                        <div className="p-2" style={{ height: '100%' }}>
                            <button className="btn btn-danger" onClick={fetchCourseNames}>
                                {showFilterForm ? 'Close Form' : 'Show Filteration Form'}
                            </button>
                            <Collapse in={showFilterForm}>
                                <div>
                                    <label htmlFor="endTime" className="form-label">Course:</label>
                                    <select className="form-select" aria-label="Default select example" value={filterCourseDropdown} onChange={handleCourseChange}>
                                        {uniqueCourses.map((el, i) => <option key={i} value={el}>{el}</option>)}
                                    </select>
                                    <label htmlFor="endTime" className="form-label">Semester:</label>
                                    <select className="form-select" aria-label="Default select example" value={filterSemesterDropdown} onChange={(e) => setFilterSemesterDropdown(e.target.value)}>
                                        {uniqueSemesters.map((el, i) => <option key={i} value={el}>{el}</option>)}
                                    </select>
                                    <div className="d-flex flex-wrap justify-content-around align-items-between">
                                        <div className="d-flex flex-wrap">
                                            <label htmlFor="endTime" className="p-2 form-label">Start Date:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={startDate.toISOString().slice(0, 10)} // Format for input value
                                                onChange={(event) => setStartDate(new Date(event.target.value))}
                                            />
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <label htmlFor="endTime" className="p-2 form-label">End Date:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={endDate.toISOString().slice(0, 10)} // Format for input value
                                                onChange={(event) => setEndDate(new Date(event.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-sm btn-warning col-md-3 col-12 my-3" onClick={filterAttendance}>Filter</button>
                                <br /><span>eg. if you want attendance from 1-10 , start date should be 1 and end date should be 11</span>
                            </Collapse>
                            {attendanceData.length !== 0 ?
                                <div className="row">
                                    {attendanceData.map((a, i) => (
                                        <div key={i} className={`card bg-transparent col-5 border-2 p-2 my-3 m-4 ${a.accepting ? "border-danger" : "border-primary"}`}>
                                            <div>
                                                <div>
                                                    <span className="fw-bold">Course: </span>{a.course}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Semester: </span>{a.semester}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Subject: </span>{a.subject}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Subject Type: </span>{a.subjectType} (Batch: {a.batch})
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Date: </span>{a.createdAt.slice(0, 10)}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Timing: </span>{a.startTime + "-" + a.endTime}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Status: </span>{a.accepting ? "Open" : "Closed"}
                                                </div>
                                            </div>
                                            {/* <hr />
                                    <div className="d-flex flex-wrap justify-content-around align-items-center">
                                        <Link to="/teacher-dashboard/attendance" className="col-md" state={{ attendanceId: a._id }}>
                                            <button className="btn btn-primary btn-sm">View</button>
                                        </Link>
                                        {a.accepting ?

                                            <div>
                                                <button className="btn btn-danger btn-sm" onClick={() => {
                                                    const userResponse = window.confirm("Do you want to close the portal?")
                                                    if (userResponse) {
                                                        closePortalById(a._id)
                                                        setRefresh(prevState => !prevState)
                                                    }
                                                }}>
                                                    Close Portal
                                                </button>
                                                <span> </span>
                                                <button className="btn btn-danger btn-sm" onClick={() => {
                                                    const userResponse = window.confirm("Do you want to delete the portal?")
                                                    if (userResponse) {
                                                        deletePortalById(a._id).then(res => {
                                                            setRefresh(prevState => !prevState)
                                                        })
                                                    }
                                                }}>
                                                    Delete Portal
                                                </button>
                                            </div>

                                            : null}
                                    </div> */}
                                        </div>
                                    ))}
                                </div>
                                : <p>No active portals present</p>
                            }
                        </div>
                    </div>
                </Collapse>
                {/* <Collapse in={showFilteredData}>
                <div className="" style={{ height: '100%'}}>
                    <button className="btn btn-sm btn-warning col-md-6 col-10 my-3" onClick={() => setShowFilteredData(false)}>Remove Filters</button>
                    {filteredAttendance.length !== 0 ?
                        <div className="row">
                            {filteredAttendance.map((a, i) => (
                                <div key={i} className={`card bg-transparent col-5 border-2 p-2 my-3 m-4 ${a.accepting ? "border-danger" : "border-primary" }`}>
                                    <div>
                                        <div>
                                            <span className="fw-bold">Course: </span>{a.course}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Semester: </span>{a.semester}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Subject: </span>{a.subject}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Subject Type: </span>{a.subjectType} (Batch: {a.batch})
                                        </div>
                                        <div>
                                            <span className="fw-bold">Date: </span>{a.createdAt.slice(0, 10)}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Timing: </span>{a.startTime + "-" + a.endTime}
                                        </div>
                                        <div>
                                            <span className="fw-bold">Status: </span>{a.accepting ? "Open" : "Closed"}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex flex-wrap justify-content-around align-items-center">
                                        <Link to="/admin-dashboard/active-portals/attendance" className="col-md" state={{ attendanceId: a._id }}>
                                            <button className="btn btn-primary btn-sm">View</button>
                                        </Link>

                                        {a.accepting ?
                                            <div>
                                                <button className="btn btn-danger btn-sm" onClick={() => {
                                                    const userResponse = window.confirm("Do you want to close the portal?")
                                                    if (userResponse) {
                                                        closePortalById(a._id)
                                                        setRefresh(prevState => !prevState)
                                                    }
                                                }}>
                                                    Close Portal
                                                </button>
                                                <span> </span>
                                                <button className="btn btn-danger btn-sm" onClick={() => {
                                                    const userResponse = window.confirm("Do you want to delete the portal?")
                                                    if (userResponse) {
                                                        deletePortalById(a._id).then(res => {
                                                            setRefresh(prevState => !prevState)
                                                        })
                                                    }
                                                }}>
                                                    Delete Portal
                                                </button>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                        : <p>No active portals present</p>}
                </div>
            </Collapse> */}
            </div>
        </ProtectAdminRoute>
    )
}

export default ActivePortals